// Set breakpoints
@mixin breakpoint($point) {
  @if $point == mobile {
    @media (max-width: 767px) { @content; }
  }
  @else if $point == tablet {
    @media (max-width: 992px) { @content; }
  }
  @else if $point == tablet-landscape {
    @media (max-width: 1024px)  { @content; }
  }
  @else if $point == laptop {
    @media (min-width: 1025px) and (max-width: 1450px)  { @content; }
  }
  @else if $point == tablet-and-above {
    @media (min-width: 992px)  { @content; }
  }
  @else if $point == desktop {
    @media (min-width: 1025px)  { @content; }
  }
}

// INCLUDE
// @include breakpoint(md) {...}