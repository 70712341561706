// Imports
@import "general";

// Body
body {
    margin-top: 85px;

    @include breakpoint(mobile) {
        margin-top: 50px;
    }
}

img {
    width: auto;
    height: auto;
}

// Pagination
ul.pagination {
    line-height: 1;
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: inline-block;

    // Page item
    li.page-item { 
        float: left;
        line-height: 1;

        // Link
        a.page-link {
            height: 100%;
            padding: 14px 0;
            text-align: center;
            width: 44px;
            color: $base-color1;
            font-size: 16px;
            line-height: 1;
            text-decoration: none;
            @include transition(all .3s);
            display: inline-block;
            background-color: #fff;

            // Hover
            &:hover {
                z-index: 2;
                color: #fff;
                text-decoration: none;
                background-color: $base-color1;
                border-color: $base-color1;
            }

            // Focus
            &:focus {
                -webkit-box-shadow: none;
                -moz-box-shadow: none;
                box-shadow: none;
            }

        }

        &.active {
            a.page-link {
                z-index: 2;
                color: #fff;
                text-decoration: none;
                background-color: $base-color1;
                border-color: $base-color1;
            }
        }
    }
}

// base list
ul:not(.navigation-list):not(.parsley-errors-list):not(.sub):not(.pagination):not(.admin-mode):not(.navbar-nav):not(.icons):not(#profile):not(.inline-edit-controls):not(.dropdown-menu) {
    list-style-type: none;
    padding: 0;
    margin: 0;

    // List item
    li {
        display: inline-block;
        width: 100%;
        position: relative;
        padding-left: 40px;
        margin-bottom: 15px;

        @include breakpoint(laptop) {
            margin-bottom: 7px;
        }

        @include breakpoint(tablet-landscape) {
            margin-bottom: 7px;
        }

        &:before {
            width: 21px;
            height: 16px;
            background-image: url('../img/icons/check.svg');
            background-repeat: no-repeat;
            background-position: center center;
            -webkit-background-size: 100% 100%;
            background-size: 100% 100%;
            position: absolute;
            top: 8px;
            left: 0;
            content: '';
        }
    }
}

// Link phone & email
.link-telephone,.link-email,.link-address,.link-linkedin {
    position: relative;
    padding-left: 28px;

    // Icon
    &:before {
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center center;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto 0;
        content: '';
        position: absolute;
    }
}

// Phone
.link-telephone {

    // Icon
    &:before {
        background-image: url('../img/icons/phone.svg');
        width: 19px;
        height: 17px;
    }
}

// Email
.link-email {

    // Icon
    &:before {
        background-image: url('../img/icons/email.svg');
        width: 17px;
        height: 14px;
    }
}

// Address
.link-address {

    // Icon
    &:before {
        background-image: url('../img/icons/location.svg');
        width: 17px;
        height: 17px;
    }
}

// LinkedIn
.link-linkedin {

    // Icon
    &:before {
        background-image: url('../img/icons/linkedin.svg');
        width: 17px;
        height: 17px;
    }
}

// Intro
.intro {
    width: 100%;
    background-color: #fff;
    padding: 85px 0;

    @include breakpoint(tablet-landscape) {
        padding: 50px 0;
    }

    // p-b-110
    &.p-b-210 {
        padding-bottom: 210px;

        @include breakpoint(tablet-landscape) {
            padding-bottom: 180px;
        }

        @include breakpoint(mobile) {
            padding-bottom: 100px;
        }
    }

    // Footer connect
    &.footer-connect {
        padding-bottom: 290px;

        @include breakpoint(tablet-landscape) {
            padding-bottom: 230px;
        }

        @include breakpoint(tablet) {
            padding-bottom: 180px;
        }

        @include breakpoint(mobile) {
            padding-bottom: 150px;
        }
    }

    // Heading
    h2 {
        font-size: 36px;
        text-transform: uppercase;
        line-height: 40px;
        margin-bottom: 30px;

        @include breakpoint(tablet) {
            font-size: 30px;
            line-height: 36px;
            margin-bottom: 10px;
        }

        @include breakpoint(mobile) {
            font-size: 27px;
            line-height: 33px;
        }
    }

    // Link
    a:not(.button) {
        color: $base-font-color1;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

// triggers
.triggers {
    width: 100%;
    background-color: $base-color2;
    position: relative;
    padding-top: 0;
    padding-bottom: 165px;

    @include breakpoint(laptop) {
        padding-bottom: 100px;
    }

    @include breakpoint(tablet-landscape) {
        padding-bottom: 50px;
    }

    @include breakpoint(mobile) {
        padding-top: 40px;
        padding-bottom: 20px;
    }

    // Container
    .container {
        position: relative;
        z-index: 2;

        @include breakpoint(tablet) {
            padding-left: 5px;
            padding-right: 5px;
        }

        @include breakpoint(mobile) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    // Row
    .row.page-row-blocks {
        @include flexbox();

        @include breakpoint(tablet) {
            margin-left: -5px;
            margin-right: -5px;
        }

        @include breakpoint(mobile) {
            display: block;
            margin-left: -15px;
            margin-right: -15px;
        }
    }

    // top roundings
    &:before {
        background-image: url('../img/sections/triggers_top.svg');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center center;
        width: 100%;
        height: 124px;
        position: absolute;
        left: 0;
        right: 0;
        top: -124px;
        z-index: 1;
        content: '';

        @include breakpoint(laptop) {
            -webkit-background-size: 125% 100%;
            background-size: 125% 100%;
        }

        @include breakpoint(tablet-landscape) {
            -webkit-background-size: 125% 100%;
            background-size: 125% 100%;
        }

        @include breakpoint(mobile) {
            height: 50px;
            top: -50px;
        }
    }

    // BLock
    ._image_text {
        position: relative;

        @include breakpoint(tablet) {
            padding-left: 5px;
            padding-right: 5px;
        }

        @include breakpoint(mobile) {
            padding-left: 15px;
            padding-right: 15px;
            margin-bottom: 30px;
        }

        // Middle
        &._middle {
            position: relative;
            top: -40px;

            @include breakpoint(mobile) {
                top: 0;
            }
        }

        // Img
        img {
            position: absolute;
            left: 0;
            right: 0;
            top: 68px;
            margin: 0 auto;
            width: 65px;
            z-index: 2;

            @include breakpoint(tablet-landscape) {
                top: 48px;
            }

            @include breakpoint(tablet) {
                top: 35px;
            }
        }

        // Inner
        .inner {
            width: 100%;
            height: 100%;
            background-color: #fff;
            @include border-radius(10px);
            padding: 55px 40px;
            padding-top: 190px;
            -webkit-box-shadow: 0px 13px 32.55px 2.45px rgba(0, 0, 0, 0.09);
            -moz-box-shadow: 0px 13px 32.55px 2.45px rgba(0, 0, 0, 0.09);
            box-shadow: 0px 13px 32.55px 2.45px rgba(0, 0, 0, 0.09);
            padding-bottom: 99px;
            @include transition(all .3s);

            @include breakpoint(tablet-landscape) {
                padding: 25px 25px;
                padding-top: 170px;
                padding-bottom: 69px;
            }

            @include breakpoint(tablet) {
                padding: 15px;
                padding-top: 140px;
                padding-bottom: 59px;
            }

            @include breakpoint(mobile) {
                padding: 30px;
                padding-top: 140px;
                padding-bottom: 74px;
            }

            &._hover {
                -webkit-transform: scale(1.03);
                -moz-transform: scale(1.03);
                -ms-transform: scale(1.03);
                -o-transform: scale(1.03);
                transform: scale(1.03);
            }

            // heading
            h2 {
                font-size: 24px;
                line-height: 28px;
                margin-bottom: 25px;

                @include breakpoint(tablet-landscape) {
                    margin-bottom: 10px;
                }

                @include breakpoint(tablet) {
                    font-size: 21px;
                    line-height: 25px;
                    margin-bottom: 10px;
                }

                @include breakpoint(mobile) {
                    margin-bottom: 10px;
                    height: auto;
                    font-size: 27px;
                }

                // EM
                em {
                    font-weight: 700;
                    font-style: normal;
                }
            }

            // Button
            .button {
                font-size: 14px;
                padding-right: 40px;
                position: absolute;
                bottom: 55px;

                @include breakpoint(tablet-landscape) {
                    bottom: 25px;
                }

                @include breakpoint(tablet) {
                    bottom: 15px;
                }

                @include breakpoint(mobile) {
                    bottom: 30px;
                }
            }
        }
    }
}

// FAQ
.faq {
    padding: 0;

    // Row
    .row.page-row-blocks {
        @include flexbox();

        @include breakpoint(mobile) {
            display: block;
        }
    }

    // Image
    .faq-image {
        padding: 0;

        // Img
        img {
            object-fit: cover;
            height: 100%;
            max-width: none;
            width: 100%;
        }
    }

    // Content
    .faq-content {
        padding: 150px 135px;
        background-image: url('../img/dots.png');
        background-repeat: repeat;
        background-position: center center;
        background-size: cover;

        @include breakpoint(laptop) {
            padding: 75px 75px 150px 75px;
        }

        @include breakpoint(tablet-landscape) {
            padding: 35px;
            padding-bottom: 150px;
        }

        @include breakpoint(mobile) {
            padding: 25px 15px;
            padding-bottom: 100px;
        }

        // heading
        h2 {
            font-size: 36px;
            line-height: 40px;
            text-transform: uppercase;
            margin-bottom: 50px;
            @include breakpoint(laptop) {
                margin-bottom: 30px;
            }

            @include breakpoint(tablet-landscape) {
                margin-bottom: 30px;
            }

            @include breakpoint(tablet) {
                font-size: 25px;
                line-height: 36px;
                margin-bottom: 10px;

                br {display: none;}
            }
        }

        // List
        ul {
            margin-bottom: 35px!important;

            @include breakpoint(mobile) {
                margin-bottom: 15px!important;
            }
        }

        // Button
        .button {
            @include breakpoint(mobile) {
                line-height: 1.2;
            }
        }
    }
}

// Quotes
.quotes {
    width: 100%;
    height: auto;
    background-color: $base-color2;
    padding-top: 0;
    position: relative;
    padding-bottom: 295px;

    @include breakpoint(laptop) {
        padding-bottom: 265px;
    }

    @include breakpoint(tablet-landscape) {
        padding-bottom: 250px;
    }

    @include breakpoint(tablet) {
        padding-bottom: 180px;
    }

    @include breakpoint(mobile) {
        padding-bottom: 150px;
    }

    // Container
    .container {
        position: relative;
        z-index: 2;
    }

    // top roundings
    &:before {
        background-image: url('../img/quotes_top.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center center;
        width: 100%;
        height: 124px;
        position: absolute;
        left: 0;
        right: 0;
        top: -124px;
        z-index: 1;
        content: '';
        
        @include breakpoint(laptop) {
            -webkit-background-size: 125% 100%;
            background-size: 125% 100%;
            top: -75px;
        }

        @include breakpoint(tablet-landscape) {
            -webkit-background-size: 125% 100%;
            background-size: 125% 100%;
            top: -75px;
        }

        @include breakpoint(tablet-landscape) {
            height: 50px;
            top: -50px;
        }
    }

    // Header
    .page-row-header {
        margin-bottom: 85px;
        text-align: center;

        // heading
        h2 {
            font-size: 36px;
            line-height: 40px;
            text-transform: uppercase;

            span {
                font-weight: 700;
            }
        }
    }

    // BLock
    ._text_image {
        position: relative;

        // Inner
        .inner {
            width: 100%;
            height: auto;
            padding: 75px 60px;
            text-align: center;
            background-color: #fff;
            -webkit-box-shadow: 0px 13px 32.55px 2.45px rgba(0, 0, 0, 0.09);
            -moz-box-shadow: 0px 13px 32.55px 2.45px rgba(0, 0, 0, 0.09);
            box-shadow: 0px 13px 32.55px 2.45px rgba(0, 0, 0, 0.09);
            @include border-radius(10px);
            position: relative;
            padding-bottom: 125px;

            @include breakpoint(tablet-landscape) {
                padding: 35px 35px;
                padding-bottom: 125px;
            }

            @include breakpoint(tablet) {
                padding-top: 50px;
            }

            @include breakpoint(mobile) {
                padding-left: 20px;
                padding-right: 25px;
            }

            // Quote icon top
            &:before {
                width: 70px;
                height: 70px;
                background-image: url('../img/icons/quote.svg');
                background-position: center center;
                background-repeat: no-repeat;
                -webkit-background-size: 100% 100%;
                background-size: 100% 100%;
                position: absolute;
                top: -35px;
                left: 0;
                right: 0;
                margin: 0 auto;
                content: '';
            }

            // BLock quote
            blockquote {
                font-family: $heading-font-family;
                color: $heading-font-color1;
                font-weight: 400;
                font-size: 30px;
                line-height: 38px;
                margin: 0;
                margin-bottom: 20px;
                height: 115px;

                @include breakpoint(tablet) {
                    font-size: 21px;
                    line-height: 29px;
                    height: 90px;
                    margin-bottom: 10px;
                }

                @include breakpoint(mobile) {
                    font-size: 23px;
                    line-height: 31px;
                    height: auto;
                }
            }
        }

        // Image
        img {
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            bottom: 20px;
            max-width: 84px;
            height: auto;
        }

        // Last
        &._last {
            margin-top: 60px;

            @include breakpoint(mobile) {
            }

            // Inner
            .inner {
                background-color: $base-color1;

                // Blockquote
                blockquote {
                    color: #fff;
                }
            }
        }
    }
}

// Footer
footer {
    background-color: $base-color1;
    position: relative;

    // top roundings
    &:before {
        background-image: url('../img/sections/footer_top.svg');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center center;
        width: 100%;
        height: 123px;
        position: absolute;
        left: 0;
        right: 0;
        top: -123px;
        z-index: 1;
        content: '';
        
        @include breakpoint(laptop) {
            -webkit-background-size: 125% 100%;
            background-size: 125% 100%;
        }

        @include breakpoint(tablet-landscape) {
            -webkit-background-size: 125% 100%;
            background-size: 125% 100%;
        }

        @include breakpoint(tablet) {
            height: 75px;
            top: -75px;
        }

        @include breakpoint(mobile) {
            height: 50px;
            top: -50px;
        }
    }

    // Logo
    .logo {
        width: 112px;
        height: 112px;
        position: absolute;
        z-index: 3;
        top: -180px;
        left: 0;
        right: 0;
        margin: 0 auto;

        @include breakpoint(tablet) {
            top: -132px;
        }

        @include breakpoint(mobile) {
            top: -107px;
        }
        
        &:after {
            content: 'Advocaten';
            font-family: clavo,serif;
            color: #fff;
            font-size: 39px;
            line-height: 1;
            position: absolute;
            bottom: -55px;
            left: 50%;
            text-align: center;
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            -o-transform: translateX(-50%);
            transform: translateX(-50%);
            font-weight: 400;
            text-transform: uppercase;
        }
    }

    // Footer top
    .footer-top {
        padding-top: 175px;
        padding-bottom: 55px;
        text-align: center;

        @include breakpoint(tablet-landscape) {
            padding-top: 75px;
        }

        @include breakpoint(mobile) {
            padding-top: 100px;

            // Link
            a {
                margin-left: -28px!important;
                margin-right: 0!important;
            }
        }
        
        // Address
        .address {

            address {
                font-family: $heading-font-family;
                font-weight: 400;
                color: #fff;
                font-size: 17px;
                text-transform: uppercase;
                font-style: normal;

                span.span-wrapper {
                    @include breakpoint(tablet) {
                        display: inline-block;
                        width: 100%;
                    }

                    span {
                        margin-right: 40px;

                        @include breakpoint(tablet) {
                            margin-right: 25px;

                            &:last-of-type {
                                margin-right: 0;
                            }
                        }

                        @include breakpoint(mobile) {
                            margin-right: 0;
                        }
                    }
                }

                // Links
                a {
                    color: #fff;
                    text-decoration: none;
                    @include transition(all .3s);

                    @media screen and (min-width:1025px) {
                        &:hover {
                            opacity: 0.5;
                        }
                    }

                    @include breakpoint(mobile) {
                        width: 100%;
                    }

                    &:first-of-type {
                        margin-right: 40px;

                        @include breakpoint(tablet) {
                            margin-right: 25px;
                        }
                    }
                }
            }
        }
    }

    // Footer bottom
    .footer-bottom {
        padding: 45px 0;
        text-align: center;
        border-top: 1px solid rgba(255,255,255,0.1);

        @include breakpoint(tablet) {
            padding: 25px 0;
        }

        // Links
        a {
            color: rgba(255,255,255,0.5);
            font-family: $heading-font-family;
            text-decoration: none;
            text-transform: uppercase;
            margin-right: 50px;
            font-weight: 400;
            @include transition(all .3s);

            @media screen and (min-width: 1025px) {
                &:hover {
                    color: #fff;
                }
            }

            @include breakpoint(mobile) {
                margin-right: 0;
            }

            &:last-of-type {
                margin-right: 0;
            }
        }
    }

    // Copyright
    .copyright {margin-top:30px;text-align: center;}
    .copyright, .copyright a {
        font-size: 15px;
        color: $base-font-color1;
        text-transform: none;
        text-decoration: none;
        font-weight: 300;
    }
    .copyright a {
        margin-right: 0!important;
    }
}

// Team
.team {
    background-color: $base-color2;
    position: relative;
    padding-top: 0;
    padding-bottom: 275px;

    @include breakpoint(laptop) {
        padding-bottom: 265px;
    }

    @include breakpoint(tablet-landscape) {
        padding-bottom: 250px;
    }

    @include breakpoint(tablet) {
        padding-bottom: 180px;
    }

    @include breakpoint(mobile) {
        padding-bottom: 125px;
    }

    // Container
    .container {
        position: relative;
        z-index: 2;
    }

    // top roundings
    &:before {
        background-image: url('../img/sections/team_top.svg');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center center;
        width: 100%;
        height: 124px;
        position: absolute;
        left: 0;
        right: 0;
        top: -124px;
        z-index: 1;
        content: '';

        @include breakpoint(laptop) {
            -webkit-background-size: 125% 100%;
            background-size: 125% 100%;
        }

        @include breakpoint(tablet-landscape) {
            -webkit-background-size: 125% 100%;
            background-size: 125% 100%;
            top: -75px;
        }

        @include breakpoint(tablet-landscape) {
            height: 50px;
            top: -50px;
        }
    }

    // Flex row
    .flex-row {
        @include flexbox();

        @include breakpoint(mobile) {
            display: block;
        }
    }

    // Teammember
    .teammember {
        -webkit-box-shadow: 0px 13px 32.55px 2.45px rgba(0, 0, 0, 0.09);
        -moz-box-shadow: 0px 13px 32.55px 2.45px rgba(0, 0, 0, 0.09);
        box-shadow: 0px 13px 32.55px 2.45px rgba(0, 0, 0, 0.09);
        background-color: #fff;
        width: 100%;
        display: inline-block;
        overflow: hidden;
        height: 100%;
        padding-bottom: 255px;
        @include border-radius(10px);

        @include breakpoint(tablet-landscape) {
            padding-bottom: 223px;
        }

        @include breakpoint(tablet) {
            padding-bottom: 183px;
        }

        @include breakpoint(mobile) {
            padding-bottom: 0;
            height: auto;
            margin-bottom: 25px;
        }

        // Image
        .teammember-image {
            @include border-top-radius(10px);
            position: relative;

            // CV icon
            .cv-icon {
                width: 69px;
                height: 69px;
                position: absolute;
                left: 0;
                right: 0;
                margin: 0 auto;
                bottom: -34px;
            }
        }

        // Body
        .teammember-body {
            padding: 50px;
            //height: 100%;
            padding-bottom: 0;
            position: relative;

            @include breakpoint(tablet-landscape) {
                padding: 50px 30px;
                padding-bottom: 0;
            }

            @include breakpoint(tablet) {
                padding: 50px 20px;
                padding-bottom: 0;
            }

            @include breakpoint(mobile) {
                padding-top: 0;
            }

            // Heading first
            h2:first-of-type {
                font-size: 32px;
                font-weight: 700;
                margin-bottom: 30px;
                line-height: 1;
                text-transform: uppercase;

                @include breakpoint(mobile) {
                    display: none;
                }
            }

            h2:not(:first-of-type) {
                font-size: 17px;
                line-height: 1;
                margin-bottom: 5px;
                font-weight: 700;
            }
        }

        // Footer
        .teammember-footer {
            @include border-bottom-radius(10px);
            border-top: 1px solid #e6e8e2;
            padding: 45px 50px;
            position: absolute;
            bottom: 0;
            width: calc(100% - 30px);

            @include breakpoint(tablet-landscape) {
                padding: 30px;
            }

            @include breakpoint(tablet-landscape) {
                padding: 20px;
            }

            @include breakpoint(mobile) {
                position: static;
                width: 100%;
                margin-top: 20px;
            }

            // Strong
            strong {
                font-family: $heading-font-family;
                font-weight: 700;
                color: $base-color1;
                margin-bottom: 10px;
            }

            // Link
            a {
                color: $base-font-color1;
                text-decoration: none;
            }
        }
    }

    // Collapse wrapper
    .teammember-collapse-wrapper {

        @include breakpoint(mobile) {
            display: none;
        }
    }

    // Collapse trigger
    .teammember-collapse-trigger {
        display: none;

        @include breakpoint(mobile) {
            display: inline-block;
            width: 100%;
            padding: 50px 20px 30px 20px;
            position: relative;

            h2 {
                font-size: 27px;
                font-weight: 700;
                line-height: 1;
                text-transform: uppercase;
                color: $heading-font-color1;
            }

            // Button
            .button {
                margin-top: 20px;
            }
            
            // Active
            &._active {

            }
        }
    }
}

// News
.news {
    padding-bottom: 290px;
    background-color: $base-color2;
    position: relative;
    padding-top: 150px;
    margin-top: -57px;

    @include breakpoint(tablet-landscape) {
        padding-bottom: 230px;
        padding-top: 120px;
    }

    @include breakpoint(tablet) {
        padding-bottom: 180px;
        padding-top: 100px;
    }

    @include breakpoint(mobile) {
        padding-bottom: 150px;
    }

    // Paragraph
    p {
        margin: 0!important;
    }

    // top roundings
    &:after {
        background-image: url('../img/sections/news_top.svg');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center center;
        width: 100%;
        height: 220px;
        position: absolute;
        left: 0;
        right: 0;
        top: -220px;
        z-index: 2;
        content: '';

        @include breakpoint(laptop) {
            -webkit-background-size: 125% 100%;
            background-size: 125% 100%;
        }

        @include breakpoint(tablet-landscape) {
            -webkit-background-size: 125% 100%;
            background-size: 125% 100%;
        }

        @include breakpoint(tablet) {
            height: 130px;
            top: -130px;
        }

        @include breakpoint(mobile) {
            height: 50px;
            top: -50px;
        }
    }

    // News row
    .news-row {
        @include flexbox();
        @include flex-wrap(wrap);
        margin-left: -15px;
        margin-right: -15px;

        // Column
        .news-col {
            margin-bottom: 30px;
            &.col-count-2 {
                position: relative;
                top: -30px;

                @include breakpoint(tablet) {
                    top: inherit;
                }
            }

            @include breakpoint(tablet) {
                &:nth-child(even) {
                    position: relative;
                    top: -30px;
                }
            }

            @include breakpoint(mobile) {
                &:nth-child(even) {
                    top: inherit;
                    position: static;
                }
            }
        }

        // Newsitem
        .newsitem {
            width: 100%;
            height: 100%;
            background-color: #fff;
            @include border-radius(10px);
            -webkit-box-shadow:  0px 13px 32.55px 2.45px rgba(0, 0, 0, 0.09);
            -moz-box-shadow:  0px 13px 32.55px 2.45px rgba(0, 0, 0, 0.09);
            box-shadow:  0px 13px 32.55px 2.45px rgba(0, 0, 0, 0.09);
            @include transition(all .3s);
            cursor: pointer;
            
            @media screen and (min-width: 1025px) {
                
                &:hover {
                    -webkit-transform: scale(1.03);
                    -moz-transform: scale(1.03);
                    -ms-transform: scale(1.03);
                    -o-transform: scale(1.03);
                    transform: scale(1.03);
                }
            }

            // Image
            .newsitem-image {
                @include border-top-radius(10px);

                // Img
                img {
                    @include border-top-radius(10px);
                }
            }

            // Body
            .newsitem-body {
                padding: 40px;
                padding-bottom: 125px;
                position: relative;

                @include breakpoint(tablet-landscape) {
                    padding: 20px;
                    padding-bottom: 85px;
                }

                // Heading
                h2 {
                    font-size: 24px;
                    text-transform: uppercase;
                    font-weight: 700;

                    @include breakpoint(tablet-landscape) {
                        font-size: 21px;
                    }
                }

                // Publication date
                .date-publication {
                    font-size: 18px;
                    display: inline-block;
                    width: 100%;
                    margin-bottom: 25px;
                    line-height: 1;
                    font-family: $heading-font-family;
                    font-weight: 400;
                    color: $base-color1;
                    opacity: 0.5;

                    @include breakpoint(tablet-landscape) {
                        margin-bottom: 15px;
                    }
                }

                // button
                .button {
                    position: absolute;
                    bottom: 40px;
                    left: 40px;

                    @include breakpoint(tablet-landscape) {
                        bottom: 20px;
                        left: 20px;
                    }
                }
            }
        }
    }
}

// News detail
.news-detail {
    padding-bottom: 290px;
    background-color: $base-color2;
    position: relative;
    padding-top: 120px;
    margin-top: -57px;

    @include breakpoint(tablet-landscape) {
        padding-bottom: 230px;
        padding-top: 120px;
    }

    @include breakpoint(tablet) {
        padding-bottom: 180px;
        padding-top: 100px;
    }

    @include breakpoint(mobile) {
        padding-bottom: 150px;
    }

    // top roundings
    &:after {
        background-image: url('../img/news_top.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center center;
        width: 100%;
        height: 220px;
        position: absolute;
        left: 0;
        right: 0;
        top: -220px;
        z-index: 2;
        content: '';

        @include breakpoint(laptop) {
            -webkit-background-size: 125% 100%;
            background-size: 125% 100%;
        }

        @include breakpoint(tablet-landscape) {
            -webkit-background-size: 125% 100%;
            background-size: 125% 100%;
        }

        @include breakpoint(tablet) {
            height: 130px;
            top: -130px;
        }

        @include breakpoint(mobile) {
            height: 50px;
            top: -50px;
        }
    }

    // Paragraph
    p {

        // Last
        &:last-of-type {
            margin-bottom: 30px;
        }
    }
}

// Contact
.contact {
    width: 100%;
    background-color: #fff;
    padding: 85px 0;

    @include breakpoint(laptop) {
        padding: 50px 0;
    }

    @include breakpoint(tablet-landscape) {
        padding: 50px 0;
    }

    // p-b-110
    &.p-b-210 {
        padding-bottom: 210px;

        @include breakpoint(laptop) {
            padding-bottom: 180px;
        }

        @include breakpoint(tablet-landscape) {
            padding-bottom: 120px;
        }

        @include breakpoint(mobile) {
            padding-bottom: 75px;
        }
    }

    // Heading
    h2 {
        font-size: 36px;
        text-transform: uppercase;
        line-height: 40px;
        margin-bottom: 30px;

        @include breakpoint(tablet) {
            font-size: 30px;
            line-height: 36px;
            margin-bottom: 10px;
        }

        @include breakpoint(mobile) {
            font-size: 27px;
            line-height: 33px;
        }
    }

    // Link
    a:not(.button) {
        color: $base-font-color1;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    // Paragraph
    p {
        @include breakpoint(mobile) {

            &:last-of-type {
                margin-bottom: 30px;
            }
        }
    }

    // Iframe
    iframe {
        border: 1px solid #e8e8e8;
        width: 100%;
    }
}

// Contact form
.contact_form {
    width: 100%;
    height: auto;
    background-color: $base-color2;
    padding-top: 0;
    position: relative;
    padding-bottom: 220px;

    @include breakpoint(laptop) {
        padding-bottom: 220px;
        padding-top: 30px;
    }

    @include breakpoint(tablet-landscape) {
        padding-bottom: 220px;
        padding-top: 30px;
    }

    @include breakpoint(tablet) {
        padding-bottom: 180px;
    }

    @include breakpoint(mobile) {
        padding-bottom: 150px;
    }

    // Container
    .container {
        position: relative;
        z-index: 2;
    }

    // top roundings
    &:before {
        background-image: url('../img/sections/quotes_top.svg');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center center;
        width: 100%;
        height: 124px;
        position: absolute;
        left: 0;
        right: 0;
        top: -124px;
        z-index: 1;
        content: '';

        @include breakpoint(laptop) {
            -webkit-background-size: 125% 100%;
            background-size: 125% 100%;
            top: -75px;
        }

        @include breakpoint(tablet-landscape) {
            -webkit-background-size: 125% 100%;
            background-size: 125% 100%;
            top: -75px;
        }

        @include breakpoint(tablet-landscape) {
            height: 50px;
            top: -50px;
        }
    }

    // Heading
    h2 {
        font-size: 36px;
        text-transform: uppercase;
        line-height: 40px;
        margin-bottom: 30px;

        @include breakpoint(tablet) {
            font-size: 30px;
            line-height: 36px;
        }

        @include breakpoint(mobile) {
            font-size: 27px;
            line-height: 33px;
        }
    }

    // Paragraph
    p {
        margin: 0;
    }
}





// SITEKICK

body.sitekick {
    padding-top: 70px;

    // Navigation
    header {
        top: 70px;
    }

    // MCE body editor
    .mce-content-body {
        line-height: 1.8;
    }

    // Triggers
    .triggers {

        // Row page row blocks
        .row.page-row-blocks {
            display: block;
        }

        // Images
        .sitekick-upload-container .images-list .thumbnail-holder {position: static;}
        .sitekick-upload-container .single-upload-container .images-list .thumbnail {
            position: absolute;
            left: 0;
            right: 0;
            top: 68px;
            margin: 0 auto;
            min-width: 1px;
            min-height: 1px;
            width: 68px;
            height: 68px;

            // Img
            img {
                top: 0;
                position: relative;
            }
        }

        ._image_text {

            // Inner
            .inner {
                padding: 55px 40px;
                padding-top: 190px;

                // Button
                .button {
                    position: relative;
                    bottom: inherit;
                }
            }
        }
    }

    // FAQ
    .faq {

        .sitekick-upload-container .images-list .thumbnail {
            margin: 0;
        }
    }

    // Quotes
    .quotes {

        .sitekick-upload-container .images-list .thumbnail-holder {position:static;}
        .sitekick-upload-container .single-upload-container .images-list .thumbnail {
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            bottom: 20px;
            min-width: 1px;
            min-height: 1px;
            width: 84px;
            height: 84px;

            img {
                bottom: 0;
            }
        }
    }

    // Button
    .button {

        &:hover {
            color: $base-color1;
        }
    }

    // Header
    .header-container {

        // Form
        form {
            text-align: center!important;
        }

        // Button
        .button {

            &:hover {
                color: #fff!important;
            }
        }

        // Logo
        .header-logo._scrolled {
            top: 100px;
        }
    }
}

// SITEKICK END