// Import mixins
@import "mixins/abs-pos";
@import "mixins/border-radius";
@import "mixins/breakpoints";
@import "mixins/keyframes";
@import "mixins/transition";
@import "mixins/gutter";
@import "mixins/shorthands";
@import "mixins/flex";
@import "mixins/center";

// Import settings
@import "settings/colors";
@import "settings/font";
@import "settings/links";

// Import elements
@import "elements/button";
@import "elements/table";
@import "elements/forms";
@import "elements/navigation";
@import "elements/header";

// Import plugins
@import "plugins/animations";
@import "plugins/bootstrap";
@import "plugins/animate";
@import "plugins/navicon";

// Box sizing
* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

// Body
body {
	background-color: 	$body-bg-color;
	font-family: 		$base-font-family;
	font-size: 			$base-font-size;
	font-weight: 		$base-font-weight;
	color: 				$base-font-color1;
	margin: 			0;
	padding: 			0;
	line-height: 		$base-font-lineheight;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// Headings
h1,h2,h3,h4,h5,h6 {
	font-family: 		$heading-font-family;
	font-weight: 		$heading-font-weight;
	color: 				$heading-font-color1;
	margin: 			0;
}


// Responsive video iframes
.video-container {
	position: relative;
	padding-bottom: 56.25%; /*16:9*/
	height: 0;
	overflow: hidden;

	iframe,
	object,
	embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

// Section
section {
	padding: 50px 0;
}

// Img
img {
	max-width: 100%;
}

// Paragraph
p {
	margin-top: 0;
	margin-bottom: 30px;

	&:last-of-type {
		margin-bottom: 0;
	}
}

// Clearspace
._clearspace {
	padding: 0!important;
	margin: 0!important;
}