// Typekit
@import url("https://use.typekit.net/jab7nbx.css");

// Font weights
$weight-light: 			300;
$weight-regular: 		400;
$weight-bold: 			700;

// Font settings
$base-font-family: 		adelle-sans, sans-serif;
$base-font-weight: 		300;
$base-font-size: 		17px;
$base-font-color1: 		#5d6674;
$base-font-lineheight:  30px;

$heading-font-family:	clavo, serif;
$heading-font-weight: 	400;
$heading-font-color1:	#081b32;