header {
    width: 100%;
    height: 85px;
    background-color: #fff;
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;

    @include breakpoint(mobile) {
        height: 50px;
    }
    
    // nav
    nav {
        display: inline-block;

        @include breakpoint(mobile) {
            position: fixed;
            top: 49px;
            background-color: #fff;
            height: 100vh;
            max-width: 300px;
            text-align: left;
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
            max-height: calc(100vh - 49px);
            padding-bottom: 100px;
            right: -300px;
            opacity: 0;
            visibility: hidden;
            @include transition(all .3s);

            &._active {
                opacity: 1;
                right: 0;
                visibility: visible;
            }

            // Shadow
            &._shadow {
                -webkit-box-shadow: -365px 745px 0 745px rgba(0,0,0,0.7);
                -moz-box-shadow: -365px 745px 0 745px rgba(0,0,0,0.7);
                box-shadow: -365px 745px 0 745px rgba(0,0,0,0.7);
            }
        }
        
        // List
        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            display: inline-block;

            @include breakpoint(mobile) {
                width: 100%;
            }
            
            // List item
            li {
                display: inline-block;
                float: left;
                margin: 0 27px;
                line-height: 85px;
                position: relative;

                @include breakpoint(tablet) {
                    margin: 0 10px;
                }

                @include breakpoint(mobile) {
                    width: 100%;
                    margin: 0;
                    line-height: 1;
                }
                
                // First item
                &:first-of-type {
                    //margin-left: 0;
                }
                
                // Last item
                &:last-of-type {
                    //margin-right: 0;
                }

                // Link
                a {
                    font-family: $heading-font-family;
                    color: $base-color1;
                    font-weight: 400;
                    text-transform: uppercase;
                    font-size: 17px;
                    line-height: 1;
                    text-decoration: none;

                    // Main links
                    &:not(.sub-link) {
                        position: relative;

                        &:before {
                            height: 1px;
                            background-color: $base-color1;
                            opacity: 0.5;
                            width: 0%;
                            bottom: -3px;
                            position: absolute;
                            left: 0;
                            right: 0;
                            content: '';
                            @include transition(all .3s);
                        }

                        &.active {
                            &:before {
                                width: 100%;

                                @include breakpoint(mobile) {
                                    max-width: 35px;
                                    bottom: 11px;
                                    left: 15px;
                                }
                            }
                        }

                        @media screen and (min-width: 1025px) {

                            &:hover {
                                color: $base-color1!important;
                                &:before {
                                    width: 100%;
                                }
                            }
                        }
                    }

                    @include breakpoint(tablet) {
                        font-size: 15px;
                    }

                    @include breakpoint(mobile) {
                        width: 100%;
                        display: inline-block;
                        padding: 15px 15px;
                        border-bottom: 1px solid rgba(0,0,0,0.1);
                        font-size: 17px;
                    }

                    // Has sub
                    &.has-sub {
                        position: relative;
                        padding-right: 16px;

                        // Icon
                        &:after {
                            width: 8px;
                            height: 4px;
                            background-image: url('../img/icons/arrow-down.svg');
                            background-position: center center;
                            background-repeat: no-repeat;
                            background-size: 100% 100%;
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            right: 0;
                            margin: auto 0;
                            content: '';

                            @include breakpoint(mobile) {
                                right: 15px;
                                width: 12px;
                                height: 8px;
                            }
                        }
                    }
                }
            }
        }

        // Sub navigation
        ul.sub {
            position: absolute;
            top: 115%;
            background-color: $base-color2;
            width: 290px;
            left: 50%;
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            -o-transform: translateX(-50%);
            transform: translateX(-50%);
            opacity: 0;
            visibility: hidden;
            @include transition(all .3s);

            // Active
            &._active {
                opacity: 1;
                visibility: visible;
                top: 100%;
            }

            @include breakpoint(mobile) {
                opacity: 1;
                top: inherit;
                left: inherit;
                display: none;
                position: static;
                background-color: #fff;
                @include transition(none);
                visibility: visible;
                -webkit-transform: none;
                -moz-transform: none;
                -ms-transform: none;
                -o-transform: none;
                transform: none;
                width: 100%;
            }

            li {
                margin: 0;
                line-height: 1;
                text-align: center;
                width: 100%;
                position: relative;
                border-bottom: 1px solid rgba(0,0,0,0.1);

                @include breakpoint(mobile) {
                    border-bottom: 0;
                    text-align: left;
                }

                &:last-of-type {
                    border-bottom: 0;
                }

                &:first-of-type {
                    // Tooltip
                    &:before {
                        width: 20px;
                        height: 20px;
                        background-color: $base-color2;
                        position: absolute;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                        top: -10px;
                        content: '';
                        -webkit-transform: rotate(-45deg);
                        -moz-transform: rotate(-45deg);
                        -ms-transform: rotate(-45deg);
                        -o-transform: rotate(-45deg);
                        transform: rotate(-45deg);

                        @include breakpoint(mobile) {
                            display: none;
                        }
                    }
                }

                // Link
                a {
                    padding: 30px 35px;
                    font-size: 17px;
                    display: inline-block;
                    width: 100%;
                    @include transition(all .3s);

                    @media screen and (min-width: 1025px) {
                        &:hover {
                            opacity: 0.7;
                            color: $base-color1!important;
                        }
                    }

                    @include breakpoint(mobile) {
                        padding: 15px;
                        padding-left: 25px;
                    }
                }
            }
        }
    }

    // Wrappers
    .nav-list-wrapper {
        float: left;
        @include transition(all .3s);
    }

    // _logo
    &._logo {

        // Wrappers
        .nav-list-wrapper {

            // First
            &._first {
                -webkit-transform: translateX(-95px);
                -moz-transform: translateX(-95px);
                -ms-transform: translateX(-95px);
                -o-transform: translateX(-95px);
                transform: translateX(-95px);

                @include breakpoint(tablet) {
                    -webkit-transform: translateX(-65px);
                    -moz-transform: translateX(-65px);
                    -ms-transform: translateX(-65px);
                    -o-transform: translateX(-65px);
                    transform: translateX(-65px);
                }
            }

            // Last
            &._last {
                -webkit-transform: translateX(40px);
                -moz-transform: translateX(40px);
                -ms-transform: translateX(40px);
                -o-transform: translateX(40px);
                transform: translateX(40px);
                
                @include breakpoint(tablet) {
                    -webkit-transform: translateX(20px);
                    -moz-transform: translateX(20px);
                    -ms-transform: translateX(20px);
                    -o-transform: translateX(20px);
                    transform: translateX(20px);
                }
            }
        }
    }

    // Hamburger
    .hamburger {
        display: none;

        @include breakpoint(mobile) {
            display: inline-block;
            float: right;
            padding: 0;
            height: 50px;
            line-height: 62px;
            opacity: 1!important;
        }
    }

    // Mobile logo
    .mobile-logo {
        display: none;

        @include breakpoint(mobile) {
            height: 50px;
            line-height: 50px;
            display: inline-block;
            float: left;

            img {
                width: 40px;
                height: 40px;
                margin-top: 5px;
            }
        }
    }
}