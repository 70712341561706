// Container
.header-container {
    margin-bottom: 57px;
    height: 625px!important;

    @include breakpoint(laptop) {
        height: 510px!important;
    }

    @include breakpoint(tablet-landscape) {
        height: 450px!important;
    }

    @include breakpoint(mobile) {
        height: 350px!important;
    }

    // Header content
    .header-content {
        z-index: 3!important;
    }

    // heading
    h1 {
        color: #fff;
        font-size: 60px;
        line-height: 1;
        margin-top: 0;
        margin-bottom: 10px;

        @include breakpoint(mobile) {
            font-size: 36px;
        }

        // EM
        em {
            font-weight: 700;
            font-style: normal;
        }
    }

    // Paragraph
    p {
        font-family: $heading-font-family;
        color: #fff;
        font-weight: 300;
        font-size: 40px;
        margin-bottom: 30px;
        line-height: 1.2;

        @include breakpoint(mobile) {
            font-size: 26px;

            br {display: none;}
        }
    }

    // Bottom roundings
    &:after {
        background-image: url('../img/sections/header_bottom.svg');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center center;
        width: 100%;
        height: 220px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        content: '';
        
        @include breakpoint(laptop) {
            -webkit-background-size: 125% 100%;
            background-size: 125% 100%;
        }

        @include breakpoint(tablet-landscape) {
            -webkit-background-size: 125% 100%;
            background-size: 125% 100%;
        }

        @include breakpoint(tablet) {
            height: 130px;
        }

        @include breakpoint(mobile) {
            height: 50px;
        }
    }

    // Button
    .button {
        background-color: #fff;
        color: $base-color1;
        border-color: #fff;

        @media screen and (min-width: 1025px) {
            &:hover {
                background-color: $base-color1;
                color: #fff;
                border-color: $base-color1;
            }
        }
    }

    // Logo
    .header-logo {
        width: 112px;
        height: 112px;
        position: absolute;
        bottom: -57px;
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: 3;
        @include transition(all .3s);
        z-index: 999;

        @include breakpoint(mobile) {
            z-index: 15;
        }

        &._z-index {
            //z-index: 999;
        }

        // Scrolled
        &._scrolled {
            position: fixed;
            bottom: inherit;
            top: 25px;
            -webkit-transform: scale(0.7);
            -moz-transform: scale(0.7);
            -ms-transform: scale(0.7);
            -o-transform: scale(0.7);
            transform: scale(0.7);
        }
    }
}