.button {
	width: auto;
	height: auto;
	@include transition(all .3s);
	outline: none;
	display: inline-block;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	font-size: $base-font-size;
	color: #fff;
	font-weight: 400;
	font-family: $heading-font-family;
	background-color: $base-color1;
	@include border-radius(5px);
	padding: 13px 18px;
	line-height: 1;
	text-decoration: none;
	text-transform: uppercase;
	position: relative;
	padding-right: 45px;
	cursor: pointer;
	border: 2px solid $base-color1;

	// Arrow
	&:after {
		width: 15px;
		height: 11px;
		background-image: url('../img/icons/arrow-right.svg');
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 100% 100%;
		content: '';
		top: 0;
		bottom: 0;
		margin: auto 0;
		right: 17px;
		position: absolute;
		@include transition(all .3s);
	}

	@media screen and (min-width: 1025px) {
		&:hover {
			background-color: transparent;
			color: $base-color1;

			&:after {
				right: 10px;
			}
		}
	}

	// Arrow-reverse
	&.arrow-reverse {
		padding: 15px 20px;
		padding-left: 45px;

		// Arrow
		&:after {
			left: 17px;
			background-image: url('../img/icons/arrow-left.svg');
		}

		@media screen and (min-width: 1025px) {
			&:hover {

				&:after {
					left: 10px;
				}
			}
		}
	}
}